@import "react-datepicker/dist/react-datepicker.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 16px;
}

/* scale down rem on desktop */
@media only screen {
  html {
    font-size: 16px;
  }
}

/* Inter var */
@font-face {
  font-family: "Inter var";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/Inter-roman.var.woff2) format("woff2");
  font-named-instance: "Regular";
}

@font-face {
  font-family: "Inter var";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(/Inter-italic.var.woff2) format("woff2");
  font-named-instance: "Italic";
}
